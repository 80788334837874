/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 09:37:36
 * @LastEditors: hutian
 * @LastEditTime: 2020-12-31 10:40:27
 */
export const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '供应商ID',
        dataIndex: 'chName',
        key: 'chName',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '供应商名称',
        dataIndex: 'enName',
        key: 'enName',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '联系人',
        dataIndex: 'sort',
        key: 'sort',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '联系电话',
        dataIndex: 'logo',
        key: 'logo',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '供应商地址',
        dataIndex: 'url',
        key: 'url',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '状态',
        dataIndex: 'createUser',
        key: 'createUser',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '操作时间',
        dataIndex: 'story',
        key: 'story',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '操作',
        dataIndex: 'flagEnable',
        key: 'flagEnable',
        /*width:150,*/
        align:'center',
        ellipsis:true
    }
]
